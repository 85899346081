import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Staffs.css";


function Staffs(props) {

    console.log(props)
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={props.stylez ? props.stylez : { display: "block" }}>

            <div className="py-8 md:py-16 text-left" >
                <div className="m-auto" style={{ maxWidth: "1280px" }}>
                    <div className="flex flex-wrap py-4">
                        {data && data.map((service, yndex) => (
                            <div key={`service-${service.title}`} className="w-full md:w-1/4 flex relative">
                                <Link to={`${service.uri}`} className="m-8 m-1 relative" >
                                    <div>
                                        <img
                                            src={service.featuredImage.node.sourceUrl}
                                            alt={service.featuredImage.node.altText}
                                            className="m-auto sc-m-img" />

                                    </div>
                                    <div className="flex items-center pb-4 pt-4">
                                        <div className="w-full">
                                            <h2 className="service-h4" style={{ minHeight: "50px" }}>
                                                {htmlDecode(service.title)}
                                            </h2>
                                            <div className="pt-2" style={{ minHeight: "58px" }} dangerouslySetInnerHTML={{ __html: service.excerpt }} />
                                        </div>
                                    </div>
                                    <div className="main-btn-container">
                                        <Link to={`${service.uri}`} className="main-button-blue mt-4 md:mt-0">
                                            {props.lang === "EL" ? "Διαβάστε Περισσότερα" : "Read More"}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Staffs;