import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
// import Introduction from "../components/Services/Introduction"
import Staffs from '../components/Staffs/Staffs'
import Breadcrumb from "../components/Global/Breadcrumb"



function StaffsTemplate(props) {
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}${props.pageContext.currentPage}`
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>
                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>
                    <section>
                        <Staffs
                            data= {data.allWpStaff.nodes}
                            lang={props.pageContext.language}
                        />
                    </section>
                </div>
            </Layout>
        </div>
    )
}

export default StaffsTemplate

export const pageQuery = graphql`query GET_STAFFS($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
      
    }

    }

    allWpStaff(limit:100, filter:{language: {code: {eq: $language}}}){
          nodes{
            content
            slug
            uri
            excerpt
            title
            featuredImage{
              node{
                altText
                sourceUrl
               
              }
            }
            staffAcf{
              staffSocial{
                facebook
                instagram
                twitter
                website
                youtube
              }
            }
          
        }
    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`

